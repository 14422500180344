.MuiAvatar-root {
	margin: 30px auto;
}
.login-container .MuiTypography-root {
	margin: 0 auto;
}
.childTable {
	background: #f2f2f2;
}
.MuiTableHead-root th {
	font-size: 0.875rem;
}
.makeStyles-reportSearchBox-8 {
	margin-bottom: 40px !important;
}

.report-search-button {
	margin-left: 10px !important;
	padding: 13px !important;
	// background-color: #558db1 !important;
}
.makeStyles-content-9 {
	height: auto !important;
}
.logo-image {
	width: 80%;
}
.loading-logo {
	display: block;
	margin: 100px auto 25px auto;
	width: 250px;
}
.MuiAppBar-colorPrimary {
	// background-color: #558db1!important;
}
.profileMenuButton {
	right: 10%;
	color: #fff !important;
	min-width: 30px;
}
.MuiTablePagination-root {
	border-bottom: none !important;
}
.no-records {
	text-align: center !important;
	padding: 15px !important;
}
#setting-menu {
	float: right;
}
.MuiContainer-root.makeStyles-container-3 {
	padding-top: 16px;
	padding-bottom: 16px;
}
.show-loader .MuiContainer-root.MuiContainer-maxWidthSm {
	z-index: 999999;
	width: 100% !important;
	background-color: #ffffff;
	position: fixed;
	top: 0;
	bottom: 0;
	opacity: 0.8;
	max-width: 100%;
	height: 100%;
}

.setting-menu {
	z-index: 999;
}

#downloadButton {
	order: 1;
}

.MuiFormLabel-root {
	font-size: 12px !important;
}
.MuiMenuItem-root {
	font-size: 12px !important;
}
.MuiSelect-select {
	font-size: 12px !important;
}
.MuiInputBase-input {
	font-size: 12px !important;
}
.MuiAutocomplete-popper li {
	font-size: 12px !important;
}
.multiple-autocomplete .MuiAutocomplete-inputRoot {
	max-height: 27px;
	font-size: 12px !important;
}
.multiple-autocomplete .MuiInputBase-root {
	line-height: 2.1876em !important;
}
.multiple-autocomplete .MuiAutocomplete-inputRoot {
	flex-wrap: nowrap !important;
}
h6.MuiTypography-root {
	font-size: 12px !important;
}
.MuiSelect-selectMenu {
	max-height: 14px;
}
.MuiPickersDay-daySelected {
	background-color: #548db1 !important;
}

.history-table .MuiToolbar-root div:nth-child(2) {
	display: flex;
	justify-content: flex-end;
}
.history-table .MuiToolbar-root span:nth-child(1) {
	order: 2;
}
.history-table .MuiToolbar-root span:nth-child(2) {
	order: 3;
}
.date-range-picker .MuiTypography-root {
	font-size: 14px;
}
.audit-log-search .MuiAutocomplete-root,
.audit-log-search .MuiInputBase-root {
	width: 160px !important;
}
